@import url(https://fonts.googleapis.com/css?family=Roboto);
.ReactCrop {
  position: relative;
  display: inline-block;
  cursor: crosshair;
  overflow: hidden;
  max-width: 100%; }
  .ReactCrop:focus {
    outline: none; }
  .ReactCrop--disabled, .ReactCrop--locked {
    cursor: inherit; }
  .ReactCrop__image {
    display: block;
    max-width: 100%;
    -ms-touch-action: manipulation;
        touch-action: manipulation; }
  .ReactCrop__crop-selection {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    cursor: move;
    -webkit-box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
            box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
    -ms-touch-action: manipulation;
        touch-action: manipulation;
    border: 1px solid;
    border-image-source: url("data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEI5RDc5MTFDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEI5RDc5MTBDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEBQoAAgAsAAAAAAoACgAAAhWEERkn7W3ei7KlagMWF/dKgYeyGAUAIfkEBQoAAgAsAAAAAAoACgAAAg+UYwLJ7RnQm7QmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYgLJHdiinNSAVfOEKoUCACH5BAUKAAIALAAAAAAKAAoAAAIRVISAdusPo3RAzYtjaMIaUQAAIfkEBQoAAgAsAAAAAAoACgAAAg+MDiem7Q8bSLFaG5il6xQAIfkEBQoAAgAsAAAAAAoACgAAAg+UYRLJ7QnQm7SmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYBLJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==");
    border-image-slice: 1;
    border-image-repeat: repeat; }
    .ReactCrop--disabled .ReactCrop__crop-selection {
      cursor: inherit; }
    .ReactCrop--circular-crop .ReactCrop__crop-selection {
      border-radius: 50%;
      -webkit-box-shadow: 0px 0px 1px 1px white, 0 0 0 9999em rgba(0, 0, 0, 0.5);
              box-shadow: 0px 0px 1px 1px white, 0 0 0 9999em rgba(0, 0, 0, 0.5); }
  .ReactCrop--invisible-crop .ReactCrop__crop-selection {
    display: none; }
  .ReactCrop__rule-of-thirds-vt::before, .ReactCrop__rule-of-thirds-vt::after, .ReactCrop__rule-of-thirds-hz::before, .ReactCrop__rule-of-thirds-hz::after {
    content: '';
    display: block;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.4); }
  .ReactCrop__rule-of-thirds-vt::before, .ReactCrop__rule-of-thirds-vt::after {
    width: 1px;
    height: 100%; }
  .ReactCrop__rule-of-thirds-vt::before {
    left: 33.3333%;
    left: calc(100% / 3); }
  .ReactCrop__rule-of-thirds-vt::after {
    left: 66.6666%;
    left: calc(100% / 3 * 2); }
  .ReactCrop__rule-of-thirds-hz::before, .ReactCrop__rule-of-thirds-hz::after {
    width: 100%;
    height: 1px; }
  .ReactCrop__rule-of-thirds-hz::before {
    top: 33.3333%;
    top: calc(100% / 3); }
  .ReactCrop__rule-of-thirds-hz::after {
    top: 66.6666%;
    top: calc(100% / 3 * 2); }
  .ReactCrop__drag-handle {
    position: absolute; }
    .ReactCrop__drag-handle::after {
      position: absolute;
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      background-color: rgba(0, 0, 0, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.7);
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      outline: 1px solid transparent; }
  .ReactCrop .ord-nw {
    top: 0;
    left: 0;
    margin-top: -5px;
    margin-left: -5px;
    cursor: nw-resize; }
    .ReactCrop .ord-nw::after {
      top: 0;
      left: 0; }
  .ReactCrop .ord-n {
    top: 0;
    left: 50%;
    margin-top: -5px;
    margin-left: -5px;
    cursor: n-resize; }
    .ReactCrop .ord-n::after {
      top: 0; }
  .ReactCrop .ord-ne {
    top: 0;
    right: 0;
    margin-top: -5px;
    margin-right: -5px;
    cursor: ne-resize; }
    .ReactCrop .ord-ne::after {
      top: 0;
      right: 0; }
  .ReactCrop .ord-e {
    top: 50%;
    right: 0;
    margin-top: -5px;
    margin-right: -5px;
    cursor: e-resize; }
    .ReactCrop .ord-e::after {
      right: 0; }
  .ReactCrop .ord-se {
    bottom: 0;
    right: 0;
    margin-bottom: -5px;
    margin-right: -5px;
    cursor: se-resize; }
    .ReactCrop .ord-se::after {
      bottom: 0;
      right: 0; }
  .ReactCrop .ord-s {
    bottom: 0;
    left: 50%;
    margin-bottom: -5px;
    margin-left: -5px;
    cursor: s-resize; }
    .ReactCrop .ord-s::after {
      bottom: 0; }
  .ReactCrop .ord-sw {
    bottom: 0;
    left: 0;
    margin-bottom: -5px;
    margin-left: -5px;
    cursor: sw-resize; }
    .ReactCrop .ord-sw::after {
      bottom: 0;
      left: 0; }
  .ReactCrop .ord-w {
    top: 50%;
    left: 0;
    margin-top: -5px;
    margin-left: -5px;
    cursor: w-resize; }
    .ReactCrop .ord-w::after {
      left: 0; }
  .ReactCrop__disabled .ReactCrop__drag-handle {
    cursor: inherit; }
  .ReactCrop__drag-bar {
    position: absolute; }
    .ReactCrop__drag-bar.ord-n {
      top: 0;
      left: 0;
      width: 100%;
      height: 6px;
      margin-top: -3px; }
    .ReactCrop__drag-bar.ord-e {
      right: 0;
      top: 0;
      width: 6px;
      height: 100%;
      margin-right: -3px; }
    .ReactCrop__drag-bar.ord-s {
      bottom: 0;
      left: 0;
      width: 100%;
      height: 6px;
      margin-bottom: -3px; }
    .ReactCrop__drag-bar.ord-w {
      top: 0;
      left: 0;
      width: 6px;
      height: 100%;
      margin-left: -3px; }
  .ReactCrop--new-crop .ReactCrop__drag-bar,
  .ReactCrop--new-crop .ReactCrop__drag-handle,
  .ReactCrop--fixed-aspect .ReactCrop__drag-bar {
    display: none; }
  .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n,
  .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e,
  .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s,
  .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
    display: none; }
  @media (pointer: coarse) {
    .ReactCrop .ord-n,
    .ReactCrop .ord-e,
    .ReactCrop .ord-s,
    .ReactCrop .ord-w {
      display: none; }
    .ReactCrop__drag-handle {
      width: 24px;
      height: 24px; } }

html {
  font-family: 'Roboto', sans-serif;
}
h1 {
  margin: 0.67em;
}
label + .MuiInput-formControl-16 {
  margin-top: 12px !important;
}
.material-ui-rc-color-picker-panel-inner {
  position: relative;
  border-radius: 4px;
  padding-bottom: 8px;
}
.material-ui-rc-color-picker-panel-wrap {
  margin: 5px 0 0;
  height: 30px;
  width: 100%;
  position: relative;
}
.material-ui-rc-color-picker-panel-wrap-preview {
  position: absolute;
  right: 8px;
}
.material-ui-rc-color-picker-panel-wrap-ribbon {
  position: absolute;
  left: 8px;
  top: 0;
  right: 43px;
  height: 30px;
}
.material-ui-rc-color-picker-panel-wrap-alpha {
  position: absolute;
  left: 8px;
  right: 43px;
  bottom: 0;
  height: 12.5px;
}
.material-ui-rc-color-picker-panel-wrap-has-alpha .material-ui-rc-color-picker-panel-wrap-ribbon {
  height: 12.5px;
}
.material-ui-rc-color-picker-trigger {
  border: 1px solid #999;
  display: inline-block;
  padding: 2px;
  border-radius: 2px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 20px;
  height: 20px;
  cursor: pointer;
  box-shadow: 0 0 0 2px #fff inset;
}
.material-ui-rc-color-picker-trigger-open {
  box-shadow: 0px 0px 3px #999;
}
.material-ui-rc-color-picker-panel {
  width: 250px;
  box-sizing: border-box;
  outline: none;
  z-index: 9;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.material-ui-rc-color-picker-panel * {
  box-sizing: border-box;
}
.material-ui-rc-color-picker-panel-open {
  display: block;
}
.material-ui-rc-color-picker-panel-close {
  display: none;
}
.material-ui-rc-color-picker-panel-preview {
  height: 30px;
  width: 30px;
  overflow: hidden;
  background-image: url('data:image/png;base64,R0lGODdhCgAKAPAAAOXl5f///ywAAAAACgAKAEACEIQdqXt9GxyETrI279OIgwIAOw==');
}
.material-ui-rc-color-picker-panel-preview span {
  box-shadow: 0 0 2px #808080 inset;
}
.material-ui-rc-color-picker-panel-preview span,
.material-ui-rc-color-picker-panel-preview input[type=color] {
  position: absolute;
  display: block;
  height: 100%;
  width: 30px;
}
.material-ui-rc-color-picker-panel-preview input[type=color] {
  opacity: 0;
}
.material-ui-rc-color-picker-panel-board {
  position: relative;
  font-size: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: 8px 8px 0px;
}
.material-ui-rc-color-picker-panel-board span {
  position: absolute;
  border-radius: 10px;
  border: 1px solid #fff;
  width: 9px;
  height: 9px;
  margin: -4px 0 0 -4px;
  left: -999px;
  top: -999px;
  box-shadow: 0 0 1px rgba(120, 120, 120, 0.7);
  z-index: 2;
}
.material-ui-rc-color-picker-panel-board-hsv {
  height: 180px;
  position: relative;
  z-index: 1;
}
.material-ui-rc-color-picker-panel-board-value {
  border-radius: 2px;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9InJnYigwLDAsMCkiIHN0b3Atb3BhY2l0eT0iMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
  background-image: linear-gradient(to bottom, transparent 0%, #000000 100%);
}
.material-ui-rc-color-picker-panel-board-saturation {
  border-radius: 2px;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0icmdiKDAsMCwwKSIgc3RvcC1vcGFjaXR5PSIwIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
  background-image: linear-gradient(to right, #ffffff 0%, transparent 100%);
}
.material-ui-rc-color-picker-panel-board-handler {
  cursor: crosshair;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.material-ui-rc-color-picker-panel-ribbon {
  position: relative;
  height: 100%;
  border-radius: 2px;
  box-shadow: 0 0 2px #808080 inset;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNmZjAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAlIiBzdG9wLWNvbG9yPSIjZmY5OTAwIiBzdG9wLW9wYWNpdHk9IjEiLz48c3RvcCBvZmZzZXQ9IjIwJSIgc3RvcC1jb2xvcj0iI2NkZmYwMCIgc3RvcC1vcGFjaXR5PSIxIi8+PHN0b3Agb2Zmc2V0PSIzMCUiIHN0b3AtY29sb3I9IiMzNWZmMDAiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iNDAlIiBzdG9wLWNvbG9yPSIjMDBmZjY2IiBzdG9wLW9wYWNpdHk9IjEiLz48c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iIzAwZmZmZCIgc3RvcC1vcGFjaXR5PSIxIi8+PHN0b3Agb2Zmc2V0PSI2MCUiIHN0b3AtY29sb3I9IiMwMDY2ZmYiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iNzAlIiBzdG9wLWNvbG9yPSIjMzIwMGZmIiBzdG9wLW9wYWNpdHk9IjEiLz48c3RvcCBvZmZzZXQ9IjgwJSIgc3RvcC1jb2xvcj0iI2NkMDBmZiIgc3RvcC1vcGFjaXR5PSIxIi8+PHN0b3Agb2Zmc2V0PSI5MCUiIHN0b3AtY29sb3I9IiNmZjAwOTkiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
  background-image: linear-gradient(to right, #ff0000 0%, #ff9900 10%, #cdff00 20%, #35ff00 30%, #00ff66 40%, #00fffd 50%, #0066ff 60%, #3200ff 70%, #cd00ff 80%, #ff0099 90%, #ff0000 100%);
}
.material-ui-rc-color-picker-panel-ribbon span {
  position: absolute;
  top: 0;
  height: 100%;
  width: 4px;
  border: 1px solid #000000;
  padding: 1px 0;
  margin-left: -2px;
  background-color: #fff;
  border-radius: 3px;
}
.material-ui-rc-color-picker-panel-ribbon-handler {
  position: absolute;
  width: 104%;
  height: 100%;
  left: -2%;
  cursor: pointer;
}
.material-ui-rc-color-picker-panel-alpha {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 2px;
  background-image: url('data:image/png;base64,R0lGODdhCgAKAPAAAOXl5f///ywAAAAACgAKAEACEIQdqXt9GxyETrI279OIgwIAOw==');
  background-repeat: repeat;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.material-ui-rc-color-picker-panel-alpha-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  box-shadow: 0 0 2px #808080 inset;
}
.material-ui-rc-color-picker-panel-alpha span {
  position: absolute;
  top: 0;
  height: 100%;
  width: 4px;
  border: 1px solid #000000;
  padding: 1px 0;
  margin-left: -2px;
  background-color: #fff;
  border-radius: 3px;
}
.material-ui-rc-color-picker-panel-alpha-handler {
  position: absolute;
  width: 104%;
  height: 100%;
  left: -2%;
  cursor: pointer;
}
.material-ui-rc-color-picker-panel-params {
  font-size: 12px;
}
.material-ui-rc-color-picker-panel-params-input {
  overflow: hidden;
  display: flex;
  justify-content: space-around;
}
.material-ui-rc-color-picker-panel-params input {
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
}
.material-ui-rc-color-picker-panel-params-hex {
  width: 58px;
}
.material-ui-rc-color-picker-panel-params-number,
.material-ui-rc-color-picker-panel-params-alpha {
  margin-left: 8px !important;
  width: 36px;
}
.material-ui-rc-color-picker-panel-params input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.material-ui-rc-color-picker {
  position: absolute;
  left: -9999px;
  top: -9999px;
  z-index: 1000;
}
.material-ui-rc-color-picker-wrap {
  display: inline-block;
}
.material-ui-rc-color-picker-slide-up-enter {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  transform-origin: 0 0;
  display: block !important;
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.material-ui-rc-color-picker-slide-up-appear {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  transform-origin: 0 0;
  display: block !important;
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.material-ui-rc-color-picker-slide-up-leave {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  transform-origin: 0 0;
  display: block !important;
  opacity: 1;
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.material-ui-rc-color-picker-slide-up-enter.material-ui-rc-color-picker-slide-up-enter-active.material-ui-rc-color-picker-placement-bottomLeft,
.material-ui-rc-color-picker-slide-up-enter.material-ui-rc-color-picker-slide-up-enter-active.material-ui-rc-color-picker-placement-bottomRight,
.material-ui-rc-color-picker-slide-up-appear.material-ui-rc-color-picker-slide-up-appear-active.material-ui-rc-color-picker-placement-bottomLeft,
.material-ui-rc-color-picker-slide-up-appear.material-ui-rc-color-picker-slide-up-appear-active.material-ui-rc-color-picker-placement-bottomRight {
  -webkit-animation-name: rcColorPickerSlideUpIn;
          animation-name: rcColorPickerSlideUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.material-ui-rc-color-picker-slide-up-enter.material-ui-rc-color-picker-slide-up-enter-active.material-ui-rc-color-picker-placement-topLeft,
.material-ui-rc-color-picker-slide-up-enter.material-ui-rc-color-picker-slide-up-enter-active.material-ui-rc-color-picker-placement-topRight,
.material-ui-rc-color-picker-slide-up-appear.material-ui-rc-color-picker-slide-up-appear-active.material-ui-rc-color-picker-placement-topLeft,
.material-ui-rc-color-picker-slide-up-appear.material-ui-rc-color-picker-slide-up-appear-active.material-ui-rc-color-picker-placement-topRight {
  -webkit-animation-name: rcColorPickerSlideDownIn;
          animation-name: rcColorPickerSlideDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.material-ui-rc-color-picker-slide-up-leave.material-ui-rc-color-picker-slide-up-leave-active.material-ui-rc-color-picker-placement-bottomLeft,
.material-ui-rc-color-picker-slide-up-leave.material-ui-rc-color-picker-slide-up-leave-active.material-ui-rc-color-picker-placement-bottomRight {
  -webkit-animation-name: rcColorPickerSlideUpOut;
          animation-name: rcColorPickerSlideUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.material-ui-rc-color-picker-slide-up-leave.material-ui-rc-color-picker-slide-up-leave-active.material-ui-rc-color-picker-placement-topLeft,
.material-ui-rc-color-picker-slide-up-leave.material-ui-rc-color-picker-slide-up-leave-active.material-ui-rc-color-picker-placement-topRight {
  -webkit-animation-name: rcColorPickerSlideDownOut;
          animation-name: rcColorPickerSlideDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes rcColorPickerSlideUpIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}
@keyframes rcColorPickerSlideUpIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}
@-webkit-keyframes rcColorPickerSlideUpOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
}
@keyframes rcColorPickerSlideUpOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
}
@-webkit-keyframes rcColorPickerSlideDownIn {
  0% {
    opacity: 0;
    transform-origin: 100% 100%;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform-origin: 100% 100%;
    transform: scaleY(1);
  }
}
@keyframes rcColorPickerSlideDownIn {
  0% {
    opacity: 0;
    transform-origin: 100% 100%;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform-origin: 100% 100%;
    transform: scaleY(1);
  }
}
@-webkit-keyframes rcColorPickerSlideDownOut {
  0% {
    opacity: 1;
    transform-origin: 100% 100%;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 100% 100%;
    transform: scaleY(0);
  }
}
@keyframes rcColorPickerSlideDownOut {
  0% {
    opacity: 1;
    transform-origin: 100% 100%;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 100% 100%;
    transform: scaleY(0);
  }
}

